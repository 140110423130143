import axios from "axios";

const envType = {
    DEV: "development",
    STAGING: "staging",
    PROD: "production",
    US_PROD: "us_production",
}
  
const develop = envType.US_PROD;

const cred = {
    url: {
      development: "http://localhost:8080/",
      staging: "https://dev-app.insightai.in:8080/",
      production: "https://api-server.insightai.in:8080/",
      us_production: "https://fin-app.insightai.in:8080/",
    },
    client_secret: {
      development: "2d1376db3fa7e6d87e1c0e119da0d4c0d882704a94131196dd1ee550a192d4ac",
      staging: "2d1376db3fa7e6d87e1c0e119da0d4c0d882704a94131196dd1ee550a192d4ac",
      production: "ced1801c5d9387970ed36b04911971ecd6719502ea51ffdc33fa5237bd463a16",
    },
    secret_token: {
      development: "C5w6LFkJqedI7pBY9_WNiDAM9H5OkGa4oiGBlsaeVzs",
      staging: "C5w6LFkJqedI7pBY9_WNiDAM9H5OkGa4oiGBlsaeVzs",
      production: "04AppdespbfE_mXkru8qfxeXyTtBSMV83IUMwR2fjCM",
    },
    client_id: {
      development: "centerstage_dev",
      staging: "centerstage_stage",
      production: "centerstage_prod",
    },
};

const fetchUrlPrefix = () => {
    switch (develop) {
      case envType.DEV:
        return cred.url.development;
      case envType.STAGING:
        return cred.url.staging;
      case envType.PROD:
        return cred.url.production;
      case envType.US_PROD:
        return cred.url.us_production;
      default:
        return cred.url.development;
    }
  }

const BASE_URL = fetchUrlPrefix();

const headers = {
    'Content-Type': 'application/json'
};

export const submitUserQuery = async(queryParams, headers) => {
    if(!queryParams['user_query']){
        console.log("Can not process with query")
        return
    }
    if(!headers['Bot-Token']){
        console.log("Can not process without token")
        return
    }
    return await axios.get(`${BASE_URL}analytics/converse/respond_user_query/`, {
        params: queryParams,
        headers: headers,
    });
}


export const submitHelpdeskQuery = async(payload, headers) => {
  if(!headers['Bot-Token']){
      console.log("Can not process without token")
      return
  }
  return await axios.post(`${BASE_URL}analytics/converse/bot_helpdesk/`, payload, {
      headers: headers,
  });
}
