import './App.css';
import ChatBot from './components/ChatBot/index';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
        paramsObject[key] = value;
    });
    if(!paramsObject['projectPublicKey']){ 
        window.location.href = "https://insightai.in/" 
    }else{
        return <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<ChatBot/>}/>
                </Routes>
            </Router>
        </div>
    }
}

export default App;
