import React from 'react';

const ProductCard = ({image, title, description}) => {
  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '10px',
    width: '300px',
    maxWidth: '200px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '10px',
    cursor: 'pointer'
  };

  const imageStyle = {
    // width: '100%',
    maxHeight: '120px',
    borderRadius: '8px',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    padding: '10px 16px',
    borderRadius: '8px',
    cursor: 'pointer',
    marginTop: '10px',
    fontWeight: 'bold',
  };

  const processTitle = (title, description)   => {
    const isDefaultTitle = title.toLowerCase().includes('default title');
    if (isDefaultTitle) {
      const strippedDescription = description.replace(/<[^>]*>?/gm, '');
      const firstThreeWords = strippedDescription.split(' ').slice(0, 3);
      const processedTitle = firstThreeWords.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      return processedTitle;
    } else {
      return title;
    }
  }

  const openUrlInNewPage = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div onClick={()=>{openUrlInNewPage(image)}} style={cardStyle}>
      <img
        src={image}
        alt={title}
        style={imageStyle}
      />
      <div style={{
        textAlign:'center',
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        maxWidth:'100%',
        fontSize:'15px',
        marginTop:'10px'
      }}>{ title }</div>
    </div>
  );
};

export default ProductCard;
