import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import { useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faSearch, faPaperPlane, faPaperclip, faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'
import { File, Upload } from "react-feather";
import { BeatLoader, BarLoader } from "react-spinners";
import { chatMessages } from "../../util/testDB/chatMessages";
import ProductCard from "../ProductCard";
import botImage from "../../images/darkbot@2x.png";
import whiteBotImage from "../../images/bot@2x.png";
import checkSign from "../../assets/image/check.png"
import { LOCAL_KEY } from "../../util/constants/localKey";
import { submitUserQuery, submitHelpdeskQuery } from "../../services/api_service";
import MarkdownParser from "../MarkdownParser";
import moment from "moment";
import { HttpStatusCode } from "axios";

const ChatBot = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    queryParams.forEach((value, key) => {
        paramsObject[key] = value;
    });
    const logoImage = paramsObject['logo']?paramsObject['logo']:whiteBotImage;
    const background = paramsObject['color']?paramsObject['color']:'#1972f5';
    const [params, setParams] = useState(paramsObject);
    const localSessionId = localStorage.getItem(LOCAL_KEY.SESSION_ID)
    const formStatus = localStorage.getItem(LOCAL_KEY.FORM_SUBMITTED);

    // Data variable
    const [messages, setMessages] = useState([]); // These messages are loaded from th backend
    const [messagesList, setMessagesList] = useState([]); // These messages are loaded on the UI
    const [fileList, setFileList] = useState([]);
    const [file, setFile] = useState(null);
    const [sessionId, setSessionId] = useState(localSessionId);
    const [listening, setListening] = useState(false);
    // Help Desk variables
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [userMessage, setUserMessage] = useState("");
    const [helpdeskLoader, setHelpdeskLoader] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(formStatus == 'SUCCESS' ? true : false);
    const [helpFormError, setHelpFormError] = useState(null);
    

    const [message, setMessage] = useState(""); // Message entered by user
    const [selectedTab, setSelectedTab] = useState(0);
    const [showSendButton, setShowSendButton] = useState(false);

    // Loaders
    const [isChatLoaded, setIsChatLoaded] = useState(true);
    const [isDocumentLoaded, setIsDocumentLoaded] = useState(true);
    const [isChatInputDisabled, setIsChatInputDisabled] = useState(false);
    const [isFetchingResponse, setIsFetchingResponse] = useState(false);
    const [voicePermissionGranted, setVoicePermissionGranted] = useState(false);

    // Bot variables
    const { fetchOrganisationData, organisationData } = props;

    // Reference Variables
    const fileInputRef = useRef(null);
    const documentUploadRef = useRef(null);
    const chatContainerRef = useRef(null);
    const recognitionRef = useRef(null);
    const [isUserInputFocused, setIsUserInputFocused] = useState(false);


    // Sample response
    const respData = {
        "id": null,
        "session": 11,
        "message": [
            {
                "description": "7 chakra bracelet, in blue or black.",
                "handle": "chain-bracelet",
                "image": "https://cdn.shopify.com/s/files/1/0621/1688/8736/products/7-chakra-bracelet_925x_317c7f21-f5c2-4710-8d8d-874dbeafca21.jpg?v=1698484881",
                "image_description": null,
                "price": null,
                "title": "7 Shakra Bracelet"
            },
            {
                "description": "Gold bangle bracelet with studded jewels.",
                "handle": "bangle-bracelet",
                "image": "https://cdn.shopify.com/s/files/1/0621/1688/8736/products/bangle-bracelet-with-jewels_925x_940ee01d-e901-4a7c-a0bd-a3cec21630dd.jpg?v=1698484889",
                "image_description": null,
                "price": null,
                "title": "[\"Default Title\"]"
            },
            {
                "description": "Black leather bracelet with gold or silver anchor for men.",
                "handle": "leather-anchor",
                "image": "https://cdn.shopify.com/s/files/1/0621/1688/8736/products/anchor-bracelet-mens_925x_a480367e-fbdb-4181-859d-1105ffa3968f.jpg?v=1698484886",
                "image_description": null,
                "price": null,
                "title": "Anchor Bracelet Mens"
            }
        ]
    }

    // Use Effect
    // "=========================="
    useEffect(() => {
        const chatMessages = JSON.parse(localStorage.getItem(getLocalMessageKey()));
        if (!chatMessages) {
            // Fetch chat messages from backend
            setIsChatLoaded(false);
            setMessages([{
                "id": '0001',
                "message": "Hello! How can I assist you today?",
                "isBot": true,
                "time": "2024-05-22T10:00:00Z"
            }])
        }else{
            setMessages([...chatMessages]);
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    // "Server side messaging"
    // "=========================="
    const getLocalMessageKey = () => {
        if(!sessionId){ return }
        return `insightai-${sessionId}-${LOCAL_KEY.LOCAL_CHAT_MESSAGES}`
    }

    const fetchBotResponse = () => {
        setIsFetchingResponse(true);
        setIsChatInputDisabled(true);

        // location: 'IN',
        // browser: 'Chrome',
        // device_type: 'MOBILE',

        // Set up the params
        const queryParams = { user_query: message }
        // if(params['location']){ queryParams['location'] = params['location']}
        
        // Set up the headers
        const headers = { 'Bot-Token': params['projectPublicKey'] }
        if(sessionId){ headers['Bot-Session'] = sessionId }

        // const messageTimeOut = setTimeout(() => {
        //     handleBotReceivedMessage(respData.message);
        // }, 1000)

        submitUserQuery(queryParams, headers).then((response) => {
            if(response.status == HttpStatusCode.Ok){
                if(!sessionId){
                    setSessionId(response.data.session)
                    localStorage.setItem(LOCAL_KEY.SESSION_ID, response.data.session.toString())
                }
                handleBotReceivedMessage(response.data.message);
                playAlertSound();
            }else{
                handleBotReceivedMessage("We seem to be having some issue currently, please try in sometime");
                playAlertSound();
            }
        }).catch(error => {
            console.log("Error: ", error)
            handleBotReceivedMessage("We seem to be having some issue currently, please try in sometime");
            playAlertSound();
        });
    }

    // "Hanlders for chat messages"
    // "=========================="

    const playAlertSound = () => {
        const audio = new Audio('./alert.wav');
        audio.volume = 0.5;
        audio.play();
    };

    const handleBotReceivedMessage = (message) => {
        const messagePayload = {
            message: message,
            isBot: true,
            time: moment().format("hh:mm A"),
        }
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, messagePayload];
            localStorage.setItem(getLocalMessageKey(), JSON.stringify(updatedMessages));
            return updatedMessages;
        });
        setIsFetchingResponse(false);
        setIsChatInputDisabled(false);
        scrollToBottom();
    }

    const handleSendMessage = () => {
        const messagePayload = {
            message: message,
            isBot: false,
            time: moment().format("hh:mm A"),
        }
        setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages, messagePayload];
            localStorage.setItem(getLocalMessageKey(), JSON.stringify(updatedMessages));
            return updatedMessages;
        });
        setMessage("");
        const scrollTimeout = setTimeout(()=>{
            scrollToBottom();
            clearTimeout(scrollTimeout)
        }, 500)
        
        setShowSendButton(false);
        fetchBotResponse();
    }

    const handleKeyPress = async (event) => {
        if (event.key === "Enter") {
          event.preventDefault(); 
          handleSendMessage();
        }
      };

    const handleFocus = () => {}

    const handleBlur = () => {}

    const handleTextMessage = (e) => {
        setMessage(e.target.value);
        setShowSendButton(e.target.value.length > 0);
    }

    const addMesageFromVoice = (message) => {
        setMessage(message);
        setShowSendButton(true);
    }

    const renderLoaderMessage = (message) => {
        return (
            <div 
                key={message}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    marginRight: "10px",
                    marginBottom: "25px",
                }}
            >
                <div style={{height: "30px", width: "30px", borderRadius:'5px'}}>
                    <img src={logoImage} style={{maxHeight:'100%', maxWidth: '100%'}} />
                </div>
                <div style={{
                    background: "#1972f5",
                    color: "#FFF",
                    padding: "10px",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    maxWidth: "70%",
                    textAlign: "left",
                    fontSize: "0.9em",
                }}>
                    Please wait, we are processing your request <BarLoader width="250" cssOverride={{marginTop:'10px'}} color="#FFFFFF" />
                </div>
                
            </div>
        )
    }

    const renderBotMessage = (message) => {
        if(typeof(message) == 'object'){
            return renderBotProductRecommendationMessage(message);
        }
        return (
            <div key={message}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    marginRight: "10px",
                    marginBottom: "25px",
                    fontWeight: 'bold',
                }}>
                <div style={{height: "30px", width: "30px", borderRadius:'5px'}}>
                    <img src={logoImage} style={{maxHeight:'100%', maxWidth: '100%'}} />
                </div>
                <div style={{
                    background: background,
                    color: "#FFF",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    borderRadius: "10px",
                    marginLeft: "10px",
                    maxWidth: "80%",
                    textAlign: "left",
                    fontSize: "0.9em",
                }}>
                    {<MarkdownParser texts={[message]} />}
                </div>
            </div>
        )
    }

    const renderBotProductRecommendationMessage = (message) => {
        const productArray = message.map((product, index) => {
            return (
                <div key={index} className="horizontal-scroll-item">
                    <ProductCard 
                        image={product.image}
                        title={product.title}
                        description={product.description}
                    />
                </div>
            )
        });
        return (
            <div key={"100101"}
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    marginRight: "10px",
                    marginBottom: "25px",
                    fontWeight: 'bold',
                }}>
                <div style={{height: "30px", width: "30px", minWidth:'30px', borderRadius:'5px'}}>
                    <img src={logoImage} style={{maxHeight:'100%', maxWidth: '100%'}} />
                </div>
                <div style={{ width: "100%" }}>
                    <div style={{
                        background: background,
                        color: "#FFF",
                        padding: "10px",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        maxWidth: "80%",
                        textAlign: "left",
                        fontSize: "0.9em",
                    }}>
                        {"Here are your recommended products:"}
                    </div>
                    <div className="horizontal-scroll-container">
                        {productArray}
                    </div>
                </div>
                
            </div>
        )
    }

    const renderUserMessage = (message) => {
        return (
            <div style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: 'flex-start',
                marginRight: "10px",
                marginBottom: "25px",
            }}>
                <div style={{
                    background: "#ECEFF1",
                    color: "#000",
                    padding: "10px",
                    borderRadius: "10px",
                    marginRight: "10px",
                    maxWidth: "70%",
                    textAlign: "right",
                    fontSize: "0.9em",
                }}>
                    {message}
                </div>
            </div>
        )
    }

    const renderMessages = () => {
        const messageArray = messages.map((message, index) => {
            if (message.isBot) {
                return renderBotMessage(message.message);
            } else {
                return renderUserMessage(message.message);
            }
        });
        if (isFetchingResponse){
            messageArray.push(renderLoaderMessage())
        }
        return messageArray
    }

    const scrollToBottom = () => {
        const scroll = chatContainerRef.current;
        if (scroll) {
          scroll.scrollTo({
            top: scroll.scrollHeight,
            behavior: 'smooth'
          });
        }
    };

    const handleVoiceInput = () => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            if (!voicePermissionGranted) {
                recognitionRef.current = new SpeechRecognition();
                recognitionRef.current.continuous = false;
                recognitionRef.current.interimResults = false;
                recognitionRef.current.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    addMesageFromVoice(transcript, false);
                };
                setVoicePermissionGranted(true);
            }

            if (recognitionRef.current) {
                if (listening) {
                    recognitionRef.current.stop();
                    setListening(false);
                } else {
                    recognitionRef.current.start();
                    setListening(true);
                }
            }
        } else {
            console.error("Speech Recognition API is not supported in this browser.");
        }
    };

    const handleHelpDeskFormSubmit = () => {
        if(!name){
            setHelpFormError("Name is required");
            return;
        }
        if(!email){
            setHelpFormError("Email is required");
            return;
        }
        if(!mobile){
            setHelpFormError("Mobile is required");
            return;
        }
        if(!userMessage){
            setHelpFormError("Message is required");
            return;
        }

        const payload = {
            name: name,
            email: email,
            mobile: mobile,
            message: userMessage,
        }
        setHelpdeskLoader(true);

        const headers = { 'Bot-Token': params['projectPublicKey'] }
        if(sessionId){ headers['Bot-Session'] = sessionId }

        submitHelpdeskQuery(payload, headers).then((response) => {
            setHelpdeskLoader(false);
            if(response.status == HttpStatusCode.Created){
                if(!sessionId){
                    setSessionId(response.data.session)
                    localStorage.setItem(LOCAL_KEY.SESSION_ID, response.data.session.toString())
                }
                setFormSubmitted(true);
                localStorage.setItem(LOCAL_KEY.FORM_SUBMITTED, 'SUCCESS');
                setName("");
                setEmail("");
                setMobile("");
                setUserMessage("");
            }
        }).catch(error => {
            setHelpdeskLoader(false);
        });
    }

    const renderTabPanel = () => {
        if(selectedTab == 0) {
            return (
                <div className="chatbot-section">
                    <div className="message-outer-container">
                        {/* Chat container div */}
                        <div 
                        className="no-scroll-bar"
                        style={{
                            height: "80%",
                            width: "100%",
                            overflowY: "scroll",
                            maxHeight: "80%",
                        }}>
                            <div style={{height:'3vh', width:'100%', fontWeight: 'bold', fontSize: '12px', marginBottom:'10px'}}>
                                    {moment().format("dddd, D MMMM")}
                            </div>
                            <div 
                                ref={chatContainerRef}
                                className="message-container"
                            >
                                {renderMessages()}
                            </div>
                        </div>
                        {/* Typing container div */}
                        <div style={{
                            height: "20%",
                            width: "100%",
                            borderTop: "1px solid #ccc",
                        }}>
                            <div style={{
                                height: "8vh",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                            }}>
                                <input 
                                    type="text"
                                    autoFocus={true}
                                    onChange={handleTextMessage}
                                    onKeyPress={handleKeyPress}
                                    disabled={isChatInputDisabled}
                                    placeholder="So, what can I help you with?"
                                    value={message}
                                    style={{
                                        width: "80%",
                                        height: "100%",
                                        border: "none",
                                        outline: "none",
                                        fontSize: ".9em",
                                    }} 
                                />
                                <div style={{padding:'10px'}}>
                                    {
                                        showSendButton &&
                                        <button 
                                            onClick={handleSendMessage}
                                            style={{
                                                height: "5vh",
                                                background: "#1972f5",
                                                color: "#FFF",
                                                border: "none",
                                                outline: "none",
                                                cursor: "pointer",
                                                fontSize: "1em",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <FontAwesomeIcon className="fa-class" icon={faPaperPlane} />
                                        </button>
                                    }
                                </div>
                                
                            </div>
                            {/* Additinal data container */}
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                height: "30px",
                                marginLeft: "5px",
                            }}>
                                <div>
                                    {/* <FontAwesomeIcon 
                                        className="message-add-on" 
                                        icon={faPaperclip} 
                                    />
                                    <FontAwesomeIcon 
                                        className={`message-add-on ${listening?'listeningmode':''}`}
                                        icon={!listening?faMicrophone:faMicrophoneSlash} 
                                        onClick={handleVoiceInput}
                                    /> */}
                                </div>
                                <div style={{
                                    fontSize: ".9em",
                                    color: "#607D8B",
                                    cursor: "pointer",
                                }}>
                                    <a style={{textDecoration: 'none', color: '#607D8B'}} target="blank" href="https://insightai.in/">
                                        Powered by <img src={botImage} style={{height: "20px", marginBottom: '-2px'}} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (selectedTab == 1) {
            return (
                <div className="chatbot-section">
                    <div style={{
                        width: "100%",
                        height: "95vh",
                        background: "#FFF",
                        padding: "10px",
                        boxSizing: "border-box",
                    }}>
                        <div className="helpdesk-form-container">
                            {
                                formSubmitted ?
                                <div style={{height:'80vh'}}>
                                    <img 
                                        src={checkSign} 
                                        style={{
                                            height:"100px", 
                                            width:'100px',
                                            marginBottom: '20px',
                                            marginTop: '100px'
                                        }} 
                                    />
                                    <div style={{
                                        padding: '10px',
                                    }}>
                                        Form submitted successfully, we will get back to you soon.
                                    </div>
                                </div>:
                                <>
                                    <h2>Contact Us</h2>
                                    {
                                        helpFormError && <div style={{color: 'red', fontSize:'14px', textAlign:'left', marginBottom:'10px'}}>{helpFormError}</div>
                                    }
                                    <form style={{width:'100%'}}>
                                        <div className="helpdesk-form-group">
                                            <label htmlFor="name">Name</label>
                                            <input onChange={(e)=>{setName(e.target.value); setHelpFormError(null)}} type="text" id="name" name="name" required />
                                        </div>
                                        <div className="helpdesk-form-group">
                                            <label htmlFor="email">Email</label>
                                            <input onChange={(e)=>{setEmail(e.target.value); setHelpFormError(null)}} type="email" id="email" name="email" required />
                                        </div>
                                        <div className="helpdesk-form-group">
                                            <label htmlFor="mobile">Mobile</label>
                                            <input onChange={(e)=>{setMobile(e.target.value); setHelpFormError(null)}} type="tel" id="mobile" name="mobile" required />
                                        </div>
                                        <div className="helpdesk-form-group">
                                            <label htmlFor="message">Message</label>
                                            <textarea onChange={(e)=>{setUserMessage(e.target.value); setHelpFormError(null)}} id="message" name="message" rows="4" required></textarea>
                                        </div>
                                        {
                                            helpdeskLoader ?
                                            <div className="helpdesk-button" style={{backgroundColor: background}} onClick={()=>{}}><BeatLoader color="#FFF"/></div>:
                                            <div className="helpdesk-button" style={{backgroundColor: background}} onClick={handleHelpDeskFormSubmit}>Submit</div>
                                        }
                                    </form>
                                </>
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div style={{background: '#FFFFFF', width: '100%', height: '100vh', textAlign: 'center'}}>
            <div className="chatbot" style={{display: 'inline-block', maxWidth: 700}}>
                <div style={{height:'50px', paddingTop: '10px', background: background, color: '#FFFFFF', display:'flex', justifyContent: 'space-between'}}>
                    <div 
                        // className={`chat-tab ${selectedTab == 0? "active": ""}`}
                        className={`chat-tab`}
                        onClick={() => setSelectedTab(0)}
                        style={{background: selectedTab == 0? "#00000030" : "#00000000"}}
                    >
                        <FontAwesomeIcon className="fa-class" icon={faComments} /> Chat
                    </div>
                    <div 
                        className={`chat-tab`}
                        onClick={() => setSelectedTab(1)}
                        style={{background: selectedTab == 1? "#00000030" : "#00000000"}}
                    >
                        <FontAwesomeIcon className="fa-class" icon={faSearch} /> Helpdesk
                    </div>
                </div>
                {/* Chatbot container */}
                {renderTabPanel()}
            </div>
        </div>
    );
};


export default ChatBot;
